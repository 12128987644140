/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-style-guide';
import SingleButtonModalContext from '../utils/SingleButtonModalContext';
import '../../../../css/shared/modal.scss';

const SingleButtonModalProvider: React.FC = ({ children }) => {
  const [heading, setHeading] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [buttonOnPressFn, setButtonOnPressFn] = useState<() => void>(() => ({}));

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const updateModalContent = (
    newHeading: string,
    newBodyText: string,
    newButtonText: string,
    canShowErrorIcon: boolean,
    buttonOnPress: () => void
  ) => {
    setHeading(newHeading);
    setBodyText(newBodyText);
    setShowErrorIcon(canShowErrorIcon);
    setButtonText(newButtonText);
    setButtonOnPressFn(buttonOnPress);
  };

  return (
    <React.Fragment>
      <SingleButtonModalContext.Provider
        value={{
          openModal,
          closeModal,
          updateModalContent
        }}>
        {children}
        <Modal show={showModal} hide={closeModal} size='md' className='error-modal'>
          <Modal.Header title={heading} showCloseButton onClose={closeModal} />
          <Modal.Body>
            {bodyText}
            {showErrorIcon && <div className='icon-warning' />}
          </Modal.Body>
          <Modal.Footer>
            <Button size={Button.sizes.large} width={Button.widths.full} onClick={buttonOnPressFn}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </SingleButtonModalContext.Provider>
    </React.Fragment>
  );
};

export default SingleButtonModalProvider;
