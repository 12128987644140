import { TranslationConfig } from 'react-utilities';

export const experienceSubscriptionTranslationConfig: TranslationConfig = {
  common: ['CommonUI.Controls', 'CommonUI.Features'],
  feature: 'Feature.Subscription'
};

export default {
  experienceSubscriptionTranslationConfig
};
